/* Add to the top of the file or in the appropriate section */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Basic reset and defaults */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  -webkit-overflow-scrolling: touch;
}

/* Form styling */
textarea,
input {
  font-size: 16px;
  /* Prevents iOS zoom */
}

/* Mobile bottom padding */
@media (max-width: 767px) {
  .thought-form-mobile {
    padding-bottom: 80px;
  }
}

/* Add to beginning of file */
html,
body {
  overscroll-behavior: none;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

#root {
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

/* Smooth transitions for page navigation */
.page-transition-enter {
  opacity: 0;
  transform: translateY(10px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

/* Smooth appearance for lists */
.list-item {
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1);
}

/* Better touch feedback */
a,
button,
[role="button"],
.clickable {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  touch-action: manipulation;
  transition: opacity 0.15s ease, transform 0.15s ease, background-color 0.15s ease;
}

/* Animated list items */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.animated-item {
  animation: fadeIn 0.3s ease forwards;
  will-change: transform, opacity;
}

/* Make scrolling feel more natural/bouncy */
.smooth-scroll {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}